<template>
  <ul>
    <li>
      <icon v-tooltip:left="$t('actionTooltipArrived')" glyph="user" />
      {{ entered | number }}
    </li>
  </ul>
</template>
<script>
export default {
  props: ['stats'],

  computed: {
    entered() {
      return (
        (Number(this.stats['Split:a']) || 0) +
        (Number(this.stats['Split:b']) || 0) +
        (Number(this.stats['Split:c']) || 0) +
        (Number(this.stats['Split:d']) || 0) +
        (Number(this.stats['Split:e']) || 0)
      )
    }
  }
}
</script>
