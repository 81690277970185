<template>
  <div style="padding: 20px">
    <label>{{ $t('actionReportingWorkflowStats') }}</label>
    <div class="stats-grid">
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="source" />
        <div class="stats-grid-item-title">{{ $t('actionReportingStarted') }}</div>
        <div class="stats-grid-item-number">{{ startedTotal | number }}</div>
      </div>
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="flag" />
        <div class="stats-grid-item-title">{{ $t('actionReportingCompleted') }}</div>
        <div class="stats-grid-item-number">
          {{ completedTotal | number }}
          ({{ completedTotal | percentageOf(startedTotal) }})
        </div>
      </div>
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="clock" />
        <div class="stats-grid-item-title">{{ $t('actionReportingCurrentlyWaiting') }}</div>
        <div class="stats-grid-item-number">{{ waitingTotal | number }}</div>
      </div>
    </div>

    <template v-if="channels.email">
      <label>{{ $t('actionReportingEmailDeliveryStats') }}</label>
      <div class="stats-grid">
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="email" />
          <div class="stats-grid-item-title">{{ $t('actionReportingSent') }}</div>
          <div class="stats-grid-item-number">
            {{ emailSentTotal | number }} /
            {{ emailSentTotal | percentageOf(emailArrivedTotal) }}
          </div>
        </div>
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="inbox" />
          <div class="stats-grid-item-title">{{ $t('actionReportingDelivered') }}</div>
          <div class="stats-grid-item-number">
            {{ emailDeliveredTotal | number }} /
            {{ emailDeliveredTotal | percentageOf(emailSentTotal) }}
          </div>
        </div>
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="arrow-redirect" />
          <div class="stats-grid-item-title">{{ $t('actionReportingBounced') }}</div>
          <div class="stats-grid-item-number">
            {{ emailBouncesTotal | number }} /
            {{ emailBouncesTotal | percentageOf(emailSentTotal) }}
          </div>
        </div>
      </div>
      <label>{{ $t('actionReportingEmailEngagementStats') }}</label>
      <div class="stats-grid">
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="email-open" />
          <div class="stats-grid-item-title">{{ $t('actionReportingOpens') }}</div>
          <div class="stats-grid-item-number">
            <span v-if="isEmailOpenTrackingEnabled">
              {{ emailUniqueOpensTotal | number }} /
              {{ emailUniqueOpensTotal | percentageOf(emailDeliveredTotal) }}
            </span>
            <span v-else>{{ $t('actionReportingNA') }}</span>
          </div>
        </div>
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="mouse" />
          <div class="stats-grid-item-title">{{ $t('actionReportingClicks') }}</div>
          <div class="stats-grid-item-number">
            <span v-if="isEmailClickTrackingEnabled">
              {{ emailUniqueClicksTotal | number }} /
              {{ emailUniqueClicksTotal | percentageOf(emailDeliveredTotal) }}
            </span>
            <span v-else>{{ $t('actionReportingNA') }}</span>
          </div>
        </div>
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="email-unsubscribe" />
          <div class="stats-grid-item-title">{{ $t('actionReportingUnsubscribes') }}</div>
          <div class="stats-grid-item-number">
            {{ emailUnsubscribesTotal | number }} /
            {{ emailUnsubscribesTotal | percentageOf(emailDeliveredTotal) }}
          </div>
        </div>
      </div>
    </template>

    <template v-if="channels.push">
      <label>{{ $t('actionReportingPushStats') }}</label>
      <div class="stats-grid">
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="push" />
          <div class="stats-grid-item-title">{{ $t('actionReportingSent') }}</div>
          <div class="stats-grid-item-number">
            {{ pushSentTotal | number }} /
            {{ pushSentTotal | percentageOf(pushArrivedTotal) }}
          </div>
        </div>
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="mobile" />
          <div class="stats-grid-item-title">{{ $t('actionReportingDelivered') }}</div>
          <div class="stats-grid-item-number">
            {{ pushDeliveredTotal | number }} /
            {{ pushDeliveredTotal | percentageOf(pushSentTotal) }}
          </div>
        </div>
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="mouse" />
          <div class="stats-grid-item-title">{{ $t('actionReportingClicked') }}</div>
          <div class="stats-grid-item-number">
            {{ pushClickedTotal | number }} /
            {{ pushClickedTotal | percentageOf(pushDeliveredTotal) }}
          </div>
        </div>
      </div>
    </template>

    <template v-if="channels.sms">
      <label>{{ $t('actionReportingSmsDeliveryStats') }}</label>
      <div class="stats-grid">
        <div class="stats-grid-item">
          <icon class="stats-grid-item-icon" glyph="sms" />
          <div class="stats-grid-item-title">{{ $t('actionReportingSent') }}</div>
          <div class="stats-grid-item-number">
            {{ smsSentTotal | number }}
            ({{ smsSentTotal | percentageOf(smsArrivedTotal) }})
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: [
    'startedTotal',
    'completedTotal',
    'waitingTotal',

    'emailArrivedTotal',
    'emailSentTotal',
    'emailDeliveredTotal',
    'emailBouncesTotal',
    'emailUniqueOpensTotal',
    'emailUniqueClicksTotal',
    'emailUnsubscribesTotal',

    'smsArrivedTotal',
    'smsSentTotal',

    'pushSentTotal',
    'pushArrivedTotal',
    'pushDeliveredTotal',
    'pushClickedTotal',

    'channels',

    'workflow'
  ],

  computed: {
    isEmailOpenTrackingEnabled() {
      return this.workflow.features.email_open_tracking !== undefined
        ? this.workflow.features.email_open_tracking
        : true
    },

    isEmailClickTrackingEnabled() {
      return this.workflow.features.email_click_tracking !== undefined
        ? this.workflow.features.email_click_tracking
        : true
    }
  }
}
</script>
