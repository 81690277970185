<template>
  <ul>
    <li>
      <icon v-tooltip:left="$t('actionTooltipArrived')" glyph="user" />
      {{ stats['End:completed'] | number }}
    </li>
  </ul>
</template>
<script>
export default {
  props: ['stats']
}
</script>
