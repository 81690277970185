<template>
  <div style="padding: 20px">
    <label>{{ $t('actionReportingWorkflowStats') }}</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="source" />
        <div class="stats-bar-item-title">{{ $t('actionReportingStarted') }}</div>
        <div class="stats-bar-item-number">{{ startedTotal | number }}</div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="flag" />
        <div class="stats-bar-item-title">{{ $t('actionReportingCompleted') }}</div>
        <div class="stats-bar-item-number">
          {{ completedTotal | number }}
          ({{ completedTotal | percentageOf(startedTotal) }})
        </div>
      </div>
    </div>
    <hr />
    <date-line-chart
      :chart-data="chartData"
      :title="$t('actionReportingTotalProfilesInWorkflow')"
      title-class="action-color-primary"
      :yLabel="$t('actionReportingNumberOfProfiles')"
    />
  </div>
</template>
<script>
import TrendLineMixin from '@/libs/TrendLineMixin'
import DateLineChart from '@/components/DateLineChart'

export default {
  props: [
    'startedTotal',
    'completedTotal',

    'dateRange',
    'startedStats',
    'completedStats'
  ],

  mixins: [TrendLineMixin],

  components: {
    DateLineChart
  },

  computed: {
    chartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: this.$t('actionReportingChartStarted'),
            backgroundColor: 'RGBA(0, 171, 102, .2)',
            borderColor: 'RGBA(0, 171, 102, 1)',
            borderWidth: 1,
            data: this.startedStats
          },
          {
            tooltip: false,
            fill: false,
            label: this.$t('actionReportingStatsTrendLineLabel'),
            data: this.getTrendLine(this.startedStats),
            backgroundColor: 'RGBA(0, 171, 102, 1)',
            borderColor: 'RGBA(0, 171, 102, 1)',
            spanGaps: true
          },
          {
            label: this.$t('actionReportingChartCompleted'),
            backgroundColor: 'RGBA(85, 83, 83, .2)',
            borderColor: 'RGBA(85, 83, 83, 1)',
            borderWidth: 1,
            data: this.completedStats
          },
          {
            tooltip: false,
            fill: false,
            label: this.$t('actionReportingStatsTrendLineLabel'),
            data: this.getTrendLine(this.completedStats),
            backgroundColor: 'RGBA(85, 83, 83, 1)',
            borderColor: 'RGBA(85, 83, 83, 1)',
            spanGaps: true
          }
        ]
      }
    }
  }
}
</script>
