<template>
  <div>
    <h5 style="font-weight: bold">
      {{ isDefault ? '(Default)' : '' }} {{ lang | langName }}
    </h5>
    <div class="panel panel-default">
      <div class="panel-body">
        <div class="form-group">
          <label>{{ $t('messageTitleLabel') }}:</label>
          <personalised-input
            v-model="message.title"
            type="text"
            :profile-attributes="profileAttributes"
            :placeholder="$t('deviceMessageTitlePlaceholder')"
            @input="onChange"
          />
        </div>
        <div class="form-group">
          <label>{{ $t('messageTextLabel') }}:</label>
          <personalised-input
            v-model="message.text"
            type="textarea"
            :profile-attributes="profileAttributes"
            rows="5"
            :placeholder="$t('yourMessageTextLabel')"
            @input="onChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ISO6391 from 'iso-639-1'
import PersonalisedInput from '@/components/PersonalisedInput'

export default {
  components: {
    PersonalisedInput
  },

  filters: {
    langName(lang) {
      return ISO6391.getName(lang)
    }
  },
  props: ['isDefault', 'lang', 'value', 'profileAttributes'],

  data() {
    const message = JSON.parse(JSON.stringify(this.value))
    return {
      message
    }
  },

  watch: {
    value: {
      deep: true,
      handler() {
        Object.assign(this.message, JSON.parse(JSON.stringify(this.value)))
      }
    }
  },

  methods: {
    onChange() {
      this.$emit('input', JSON.parse(JSON.stringify(this.message)))
    }
  }
}
</script>
<style lang="sass" scoped></style>
