<template>
  <ul>
    <li>
      <icon v-tooltip:left="$t('delayStatTotalDelayed')" glyph="hand"/>
      {{ stats['Delay:delayed'] | number }}
    </li>
    <li>
      <icon v-tooltip:left="$t('delayStatCurrentlyWaiting')" glyph="clock"/>
      {{ waiting | number }}
    </li>
    <li>
      <icon v-tooltip:left="$t('delayStatTotalEndedEarly')" glyph="cross-circle"/>
      {{ stats['Delay:earlyCancel'] | number }}
    </li>
  </ul>
</template>
<script>
export default {
  props: ['stats'],

  computed: {
    waiting() {
      return (
        (Number(this.stats['Delay:delayed']) || 0) -
        (Number(this.stats['Delay:resumed']) || 0) -
        (Number(this.stats['Delay:cancelled']) || 0) -
        (Number(this.stats['Delay:earlyCancel']) || 0)
      )
    }
  }
}
</script>
