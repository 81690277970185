<template>
  <ul>
    <li>
      <icon v-tooltip:left="$t('actionTooltipArrived')" glyph="user" />
      {{ stats['Inbox:trigger'] | number }}
    </li>
    <li>
      <icon v-tooltip:left="$t('actionTooltipSent')" glyph="push" />
      {{ stats['Inbox:sent'] | number }}
    </li>
    <li>
      <icon v-tooltip:left="$t('actionTooltipOpened')" glyph="mouse" />
      {{ stats['Inbox:openedUnique'] | number }}
    </li>
  </ul>
</template>
<script>
export default {
  props: ['stats']
}
</script>
