<template>
  <div>
    <el-menu
      mode="horizontal"
      background-color="#505659"
      text-color="#fff"
      active-text-color="#fff"
    >
      <el-menu-item v-if="saveVisible" @click="$emit('save')">
        <i class="el-icon-check"></i>
        {{ saveLabel }}
      </el-menu-item>
      <el-menu-item v-if="previewHtmlVisible" @click="onHtmlPreview">
        <i class="el-icon-document"></i>
        {{ previewHtmlLabel }}
      </el-menu-item>
      <el-menu-item v-if="saveAsTemplateVisible" @click="onSaveAsTemplate">
        <i class="el-icon-document-copy"></i>
        {{ saveAsTemplateLabel }}
      </el-menu-item>

      <el-submenu
        v-if="previewVisible"
        index="1"
        popper-class="builder-toolbar-popup"
      >
        <template slot="title">
          <i class="el-icon-view"></i>
          {{ previewLabel }}
        </template>
        <el-menu-item v-if="previewHtmlVisible" @click="onHtmlPreview">
          <i class="el-icon-document"></i>
          {{ previewHtmlLabel }}
        </el-menu-item>
        <el-menu-item v-if="previewTextVisible" disabled>
          <i class="el-icon-toilet-paper"></i>
          {{ previewTextLabel }}
        </el-menu-item>
      </el-submenu>

      <el-menu-item v-if="discardVisible" @click="$emit('discard')">
        <i class="el-icon-close"></i>
        {{ discardLabel }}
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: {
    builder: { required: true },
    saveVisible: { default: true, type: Boolean },
    saveLabel: {
      default: function () {
        return this.$t('toolbarSave')
      }
    },

    saveAsTemplateVisible: { default: true, type: Boolean },
    saveAsTemplateLabel: {
      default: function () {
        return this.$t('toolbarSaveAsTemplate')
      }
    },

    discardVisible: { default: true, type: Boolean },
    discardLabel: {
      default: function () {
        return this.$t('toolbarDiscard')
      }
    },

    previewVisible: { default: false, type: Boolean },
    previewLabel: {
      default: function () {
        return this.$t('toolbarPreview')
      }
    },

    previewHtmlVisible: { default: true, type: Boolean },
    previewHtmlLabel: {
      default: function () {
        return this.$t('toolbarHTMLPreview')
      }
    },

    previewTextVisible: { default: true, type: Boolean },
    previewTextLabel: {
      default: function () {
        return this.$t('toolbarTextPreview')
      }
    }
  },
  methods: {
    onHtmlPreview() {
      this.builder.preview()
    },
    onSaveAsTemplate() {
      this.builder.saveAsTemplate()
    }
  }
}
</script>

<style lang="sass" scoped>
.el-menu
  height: 55px
  padding: 0
  display: flex
  justify-content: flex-end

  .el-menu-item
    display: flex
    line-height: 54px
    height: 54px

    [class^=el-icon-]
      color: #fff
      line-height: 54px
      font-weight: bold

  .el-submenu
    display: flex
    line-height: 54px
    height: 54px

    ::v-deep .el-submenu__title
      display: flex
      line-height: 54px
      height: 54px

      [class^=el-icon-], [class*= el-icon-]
        color: #fff
        line-height: 54px
        font-weight: bold
        margin-top: 0
</style>

<style lang="sass">
.builder-toolbar-popup
  margin-top: 1px

  .el-menu
    padding: 0
    margin: 0
</style>
